import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import Top from '~sections/project/Top'
//import { Link } from "gatsby";
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import InMails from "~sections/project/InMails"
import Sync from "~sections/project/Sync"
import Team from "~sections/project/Team"
import Report from "~sections/project/Report"
import ProcessSection from "~sections/marketing/Process"
import SmartSequences from "~sections/project/SmartSequences"
import Testimonial from "~sections/customer-stories/Testimonial"
import AiWriter from "~sections/project/AiWriter/AiWriter"
import PowerfulCampaign from "~sections/project/PowerfulCampaign/PowerfulCampaign"
import { Helmet } from "react-helmet";
import Automated from "~sections/project/Automated"
import VoiceNotes from "~sections/project/VoiceNotes"
import ABTesting from "~sections/project/ABTesting"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}


export default function Product() {
  return (
    <>
    <Helmet>
        <title>10 features you can’t live without</title>
        <meta name="description" content="Explore what makes We-Connect the best LinkedIn automation software on the market. Here are our 10 favorite features that we can't live without."/>
        <meta property="og:title" content="We-Connect | 10 features you can’t live without"/>
        <meta property="og:description" content="Explore what makes We-Connect the best LinkedIn automation software on the market. Here are our 10 favorite features that we can't live without."/>
        <meta property="og:image" content="https://we-connect.io/best-features-on-we-connect.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/best-features"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect | 10 features you can’t live without"/>
        <meta property="twitter:description" content="Explore what makes We-Connect the best LinkedIn automation software on the market. Here are our 10 favorite features that we can't live without."/>
        <meta property="twitter:image" content="https://we-connect.io/best-features-on-we-connect.png"/>
    
    </Helmet>
    <PageWrapper headerConfig={header}>
      <Top id="top"/>
      <SmartSequences id="smartsequences"/>
      <AiWriter id="aiwriter"/>
      <InMails id="inmails"/>
      <Automated id="greeting"/>
      <VoiceNotes id="voice"/>
      <Sync id="sync"/>
      <ABTesting id="testing"/>
      <Report id="report"/>
      <Team id="team"/>
      <PowerfulCampaign id="powerful"/>
      <ProcessSection />
      <Testimonial />
      <CtaSection />
      <FooterOne />
    </PageWrapper>
    </>
  )
}
