import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "../../../assets/image/project-management/02-FreeInmails.svg";


export default function InMails({id}){

return(
   
<Content backgroundColor="#f3f8fe" id={id}>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            
        <img  height="462" className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />

        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Free InMails"/> </Content.Title>

          <Content.Text> <SuperTag value="Stop waiting for contacts to respond to your connection requests. Instead, send free InMails to LinkedIn members with open profiles to connect instantly. Unlike connection requests, your contacts will see your entire message once they open an InMail."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Find the value behind InMails!
          </Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>
)
}