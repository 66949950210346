import Images from "~data/imageImports";
const ProcessData = [
      {
        id:"ps1",
        icon: "1",
        iconBackground: "#5034fc",
        title: "Bring contacts from your address book",
        text:
          " LinkedIn allows syncing contacts from major email providers such as Gmail, Yahoo, AOL and Outlook.",
      },
      {
        id:"ps2",
        icon: "2",
        iconBackground:"#ef4339",
        title: "One click import to We-Connect",
        text:
          " We-Connect can sync your lists from LinkedIn and automate invite campaign with follow up messaging sequences.",
      },
      {
        id:"ps3",
        icon: "3",
        iconBackground:"#0abfbc",
        title: "Start networking",
        text:
          "Grow your network and generate qualified leads. Book more meetings and demos.",
      },
  ]
  export default ProcessData;