import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/image/project-management/08-Report-analyze.svg";

export default function Report({id}){

return(

<Content id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Reporting & Analytics"/> </Content.Title>

          <Content.Text> <SuperTag value="Visualize your results from an easy-to-manage dashboard. Complete with interactive charts, the reports page is your one-stop shop to see how your campaigns are performing. From the reports page, you’re able to see statistics for specific accounts and campaigns, as well as share the data with your team."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Make data-informed decisions with We-Connect!
          </Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image>
            
        <img  height="483" src={image} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />

        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>

)
}