import React from 'react'
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';


export default function Top({id}){

return(
<Content id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Prospect ↔ Engage ↔ Connect ↔ Convert"/> </Content.Title>

          <Content.Text> <SuperTag value="Designed to maximize the productivity and safety of your account.<br className='d-none d-xs-block' /> Prospect with confidence with the most advanced yet easy to use LinkedIn software."/> </Content.Text>
          
        </Content.TextBlock>
      
      
    </Row>
    </Content.Inner>
  </Container>
</Content>
)
}
//<br className='d-none d-lg-block' />
//<br className='d-none d-xs-block' />