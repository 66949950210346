import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/image/media/send-voice-notes.svg";

export default function VoiceNotes({id}){

return(
  
<Content backgroundColor="#f3f8fe" id={id}>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            
        <img className="w-100" height="462" src={image} alt="content" layout="fullWidth" placeholder="blurred" />

        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Send Voice Notes"/> </Content.Title>

          <Content.Text> <SuperTag value="What’s more personal than a personalized message? A voice note. Send voice notes directly through your We-Connect campaigns to boost your reply rate and build stronger relationships."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Send your first voice note!
          </Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>

)
}