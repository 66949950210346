import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "../../../assets/image/project-management/01-MultiTouch.svg";

export default function SmartSequences({id}){

return(
<Content backgroundColor="#f3f8fe" id={id}>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Smart Sequences"/> </Content.Title>

          <Content.Text> <SuperTag value="Design a campaign that fits your unique needs. From nurture campaigns to first contact campaigns, smart sequences enables you to customize your campaigns to fit your use case. Visit a profile, like a post, and send a LinkedIn connection request to catch your lead's attention. From there, endorse skills and continue your messaging to build a relationship with your audience. With We-Connect, there’s no limit to the number of steps in your campaign."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Create your first campaign today!
          </Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>
)
}