import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/image/media/automated-greetings.svg";


export default function Automated({id}){

return( 
<Content id={id}>
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Automated Greetings"/> </Content.Title>

          <Content.Text> <SuperTag value="Automatically congratulate a contact on a new role, birthday, or workplace anniversary with We-Connect. Doing so helps you build a stronger rapport with your 1st-degree connections and can even help you close more deals.
"/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">See how it works in action!
          </Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image>
            
        <img src={image} height="462" alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />

        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>
)
}