import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/image/project-management/04-SyncData.svg";

export default function Sync({id}){

return(

<Content id={id}> 
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    
    <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Sync Data"/> </Content.Title>

          <Content.Text> <SuperTag value="Keep your CRM and other third-party apps up-to-date with seamless API & Zapier integration. Import and export lists of leads directly from your CRM using our API endpoints."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Integrate We-Connect with third-party apps
          </Content.Button>
        </Content.TextBlock>
      </Col>
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            
        <img height="593" src={image} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />

        </Content.Image>
      </Col>
      
    </Row>
    </Content.Inner>
  </Container>
</Content>

)
}