import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/image/media/AI-Writer.svg";

export default function AiWriter({id}){

return(
<Content id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="AI Writer"/> </Content.Title>

          <Content.Text> <SuperTag value="Streamline your messaging by using our AI Writer to craft personalized messages on your behalf. Simply type in a detailed prompt, and the AI Writer will write a message according to your instructions. It’s like having ChatGPT right inside the We-Connect dashboard for seamless communication."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Save valuable time and try our AI Writer!
          </Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="462" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}