import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "../../../assets/image/project-management/09-PowerfullCampaign.svg";


export default function PowerfulCampaign({id}){

return(
   
<Content id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Powerful Campaign Manager"/> </Content.Title>

          <Content.Text> <SuperTag value="View all your campaigns from a simple-to-manage dashboard. Easily edit, pause, and activate campaigns in only one click. Check your messages and the progress of each campaign in seconds right from the We-Connect homepage."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Manage your campaigns without limits!
          </Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="375" className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>
)
}