import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/image/project-management/07-TeamManagement.svg";

export default function Team({id}){

return(
 
<Content backgroundColor="#f3f8fe" id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            
        <img height="462" src={image} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />

        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Manage Team & Clients"/> </Content.Title>

          <Content.Text> <SuperTag value="Manage team members and client access easily within We-Connect. Assign permissions and roles for individual users within a few clicks."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Increase agency efficiency with team access!
          </Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>

)
}